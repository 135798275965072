import { Box, Container, Grid } from '@mui/material'
import React from 'react'
import HomeAccordion from './_HomeAccordion'
import HomeRight from './_HomeRight'

const HomeMain = () => {
    return (
        <Box
            sx={{
                backgroundColor: 'rgba(241, 241, 241, 0.3)',
                py: '50px'
            }}
        >

            <Container maxWidth="lg" >

                <Grid container spacing={3} >
                    {/* <Grid item xs={12} md={6} sx={{ display: { xs: 'none', md: 'flex' } }}>
                        <HomeAccordion />
                    </Grid> */}
                    <Grid item xs={12} md={12} sx={{ mb: '50px' }}>
                        <HomeRight />
                    </Grid>
                    {/* <Grid item xs={12} md={6} sx={{ display: { xs: 'flex', md: 'none' }, mb: '50px' }}>
                        <HomeAccordion />
                    </Grid> */}
                </Grid>

            </Container>

        </Box>
    )
}

export default HomeMain